.qa-table .ant-table-thead > tr > th {
	padding: 4px;
}
.qa-table .ant-table-tbody > tr > td {
	padding: 0px;
	font-size: 12px;
}

.qa-table .ant-table-cell {
	border-bottom: solid 1px rgb(0, 0, 0, 0.15);
	border-right: solid 1px rgb(0, 0, 0, 0.15);
}

.qa-table .attribute {
	background-color: rgb(from var(--color-yellow) r g b / 0.3);
}

.qa-table .bold {
	font-weight: bold;
}

.qa-table .active-value {
	background-color: #dbffdb;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.qa-table .verified {
	background-color: #dbfff8;
}
