.Login-root {
	width: 400px;
	padding: 15px;
	margin-top: 30px;
}

.Account-root {
	max-width: 1200px;
	padding: 10px;
}
