.app-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap !important;
}

.app-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 44em;
  flex: 1;
  padding-top: 1em;
  padding-left: 1.4em;
}

.sidebar-container,
.app-content,
.row {
  width: auto !important;
}

.sidebar {
  padding-right: 1.4em;
  padding-left: 1em;
  overflow-y: auto;
  height: calc(100% - 64px);
  background-color: white;
  border-right: 2px dashed  #b3b3b3;
  transition: transform 0.3s ease;
}

.border-appear {
  animation: borderAnimation 0.3s forwards;
}

.sidebar-container {
  display: flex;
  position: fixed;
  top: 64px;
  height: 100vh;
  z-index: 500;
}

button:focus {
  outline: none !important;
}

.sidebar.closed+.separator-line {
  left: 1.4em;
}


.toggle-btn:active,
.sidebar-container:hover .separator-line {
  filter: brightness(0.9);
}

.sidebar-container:hover .resizer-btn {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.sidebar:hover+.separator-line:hover .toggle-btn:focus-visible {
  outline: none;
}

.toggle-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  color: white;
  cursor: pointer;
  opacity: 0;
  background-color: var(--primary-color);
}

@media (max-width: 1090px) {
  .sidebar-container {
    position: fixed;
    z-index: 1000;
  }

  .separator-line {
    z-index: 1000;
  }

  .app-content.overlay{
  margin-left: 0 !important;
  }
  
  .app-content.overlay::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
    transition: 0.1s ease-in-out;
  }

}