.delete-attr {
	margin: -1px 0 1px 5px;
	position: center;
}
.selected {
	padding: 0.25em 0.3em;
	margin: 0.1em 0.3em 0.1em 0;
	border: 1px solid black;
}

.selected-btn {
	border: 2px solid black;
	color: black;
	margin: 10px;
	max-height: fit-content;
}

.annotation-manager {
	display: flex;
}
