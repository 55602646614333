.Navigation-Bar {
    border-radius: .5em;
	display: flex;
	flex-direction: column;
}

.library {
    padding-top: 0.7em;
    border-radius: .5em;
    z-index: 1;
}

.library > :first-child > :first-child {
    box-sizing: border-box;
}
.library > :last-child > :first-child > :last-child{
	margin-bottom: 1em;
}

.library > :first-child > :last-child {
	bottom: 1em !important;
}
.library > :last-child > :nth-child(2){
	display: none;
}
.Navigation-Bar > :first-child{
	padding-top: 1em;
}

.navigation-elem {
    position: relative;
    display: flex;
    padding: 0.7em 0 0 0.6em;
    flex-direction: row;
    align-items: center;
}

.navigation-elem:last-child {
	border-bottom: none;
}

.navigation-elem > svg {
	flex-shrink: 0;
}
.library-link {
	color: var(--color-gray);
}

.library-link:active {
	color: var(--primary-color);
}

.Navigation-class {
	margin-left: 1em;
	overflow-x: hidden;
	font-size: 11px;
}

.Navigation-attr {
	margin-left: 1em;
	overflow-x: hidden;
	font-size: 10px;
}

.batches {
	width: 100%;
	align-self: center;
}

.ant-select-selection {
	background-color: #d0b8f1;
}

.ant-select-open{
    position: fixed;
}
div[style*="position: absolute"]:has(.ant-select-dropdown) {
	position: fixed !important;
}

.navigation-elem .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: none !important;
}

.navigation-elem .ant-select-focused .ant-select-selector {
	border-color: transparent !important;
	box-shadow: none !important;
}

.navigation-elem:nth-child(2) > div{
    z-index: 4000;
    background-color: white;
   }
  
   .navigation-elem:first-child > div {
    z-index: 5000;
    background-color: white;
   }

.navigation-elem .ant-select {
	position: relative;
}

.navigation-elem .ant-select:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0px;
	height: 1px;
	background: rgba(0, 159, 152, 0.6);
	filter: blur(1px);
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.3s ease;
}

.navigation-elem .ant-select:hover:after {
	opacity: 1;
}

.navigation-elem .ant-select.ant-select-focused:after,
.navigation-elem .ant-select.ant-select-open:after {
	background: rgba(0, 159, 152, 1);
	opacity: 1;
}
