.analysis-card .ant-tabs-nav div .ant-tabs-tab {
	border: 1px solid #f0f0f0;
}

.ant-space.ant-space-vertical .ant-space-item .analysis-actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.analysis-card .ant-card-body .ant-space.ant-space-vertical {
	display: flex;
	margin-top: 8px;
}
.analysis-search {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}
