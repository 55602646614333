.resizer {
    position: fixed;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    cursor: ew-resize;
    z-index: 10000;
    padding: 0px 4px;
    background-color: transparent;
}

.resizer::before {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    background-color: #b3b3b3;
}

.resizer:hover::before {
    background-color: #989898;
    transition: background-color 0.2s ease-in-out;
}

.resizer-btn {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    color: black;
    cursor: pointer;
    opacity: 0;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 1px 4px 3px;
    background-color: white;
}

.resizer:hover .resizer-btn {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.resizer-btn:hover {
    background-color: var(--primary-color);
    color: white;
}