.analysis-table .ant-table-thead > tr > th {
	padding: 5px;
}

.analysis-table .ant-table-tbody > tr > td {
	padding: 1px;
}

/* .highlight-row:not(.ant-table-row-selected),
.highlight-row:not(.ant-table-row-selected) td.ant-table-column-sort,
.highlight-row:not(.ant-table-row-selected) td.ant-table-cell-row-hover {
	background-color: var(--row-highlight-color) !important;
} */

.highlight-row,
.highlight-row td.ant-table-column-sort,
.highlight-row td.ant-table-cell-row-hover,
.ant-table-row-selected.highlight-row td {
	background-color: var(--row-highlight-color) !important;
}
