.MuiInputBase-input {
	font: inherit;
	color: currentColor;
	width: 100%;
	border: 0;
	height: 10px;
	margin: 0;
	display: block;
	padding: 6px 0 7px;
	min-width: 0;
	background: none;
	box-sizing: content-box;
	animation-name: MuiInputBase-keyframes-auto-fill-cancel;
	-webkit-tap-highlight-color: transparent;
}
