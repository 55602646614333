.ant-card-head-title {
    display: flex !important;
}

.ant-card-head-title .ant-btn-primary{
    margin-left: auto !important;
}

.stat-card {
    width: 20rem;
    height: 8rem;
    text-align: center;
    padding: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    /* Закругление углов (8px ~ 0.5rem) */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stats-container {
    display: flex;
    align-items: center;
    /* Центрируем кнопки по вертикали */
}

.button-group {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.custom-button {
    background-color: #e0e0e0;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.custom-button.active {
    background-color: #a0a0a0;
}

.custom-button:disabled {
    cursor: not-allowed;
    /* Устанавливаем курсор в состояние "не доступно", когда кнопка отключена */
    opacity: 0.6;
    /* Можно также уменьшить непрозрачность кнопки, чтобы подчеркнуть её неактивность */
}

.custom-button:hover {
    background-color: #c0c0c0;
}