.diff-attr-name {
	font-weight: 550;
	font-style: italic;
}

.diff-title {
	font-weight: 700;
}

.diff-item {
	margin-bottom: 0px;
}
